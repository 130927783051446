<template>
  <div>
    <div class="detail-title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>教研中心</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/teacherCourse' }"
          >课程
        </el-breadcrumb-item>
        <el-breadcrumb-item>编辑课时</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content-list-page page-content" style="margin-top: 20px">
      <div class="list-head-box">
        <div class="list-head" data-v-69874ad0="">
          <p class="list-head-title" data-v-69874ad0="">说明</p>
          <ul data-v-69874ad0="">
            <li data-v-69874ad0="">文件名称应该是 《章节名称》_《小节名称》</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="detail-info">
      <el-form
        ref="form"
        v-loading="detailLoading"
        :model="form"
        :rules="rules"
        label-width="100px"
        size="mini"
      >
        <!-- <el-form-item label="名称：" label-position="left" prop="name" class="input">
          <el-input style="width: 200px" v-model="form.name"></el-input>
        </el-form-item> -->

        <el-form-item label="类型：">
          <el-radio-group v-model="form.type" size="mini">
            <!-- <el-radio-button :label="1">图文</el-radio-button> -->
            <el-radio-button :label="2">视频</el-radio-button>
            <el-radio-button :label="3">音频</el-radio-button>
          </el-radio-group>
        </el-form-item>

        <div v-if="form.type === 1">
          <el-form-item label="内容：" label-position="left" prop="contents">
            <editor :content="form.contents" @onChange="onChange"></editor>
          </el-form-item>
        </div>

        <div v-if="form.type === 2">
          <el-form-item label="选择视频：" label-position="left" prop="data">
            <media-upload
              :data="form.data"
              :uploadPath="uploadPath"
              accept=".mp4"
              @success="uploadSuccess"
            >
              <span slot="prompt">支持MP4格式，文件大小小于500M</span>
            </media-upload>
          </el-form-item>
        </div>

        <div v-if="form.type === 3">
          <el-form-item label="选择音频：" label-position="left" prop="data">
            <media-upload
              :data="form.data"
              :uploadPath="uploadPath"
              accept=".mp3"
              @success="uploadSuccess"
            >
              <span slot="prompt">支持MP3格式，文件大小小于500M</span>
            </media-upload>
          </el-form-item>
        </div>

        <!-- <el-form-item label="所属章节：" label-position="left" class="input" prop="teacherCourseChapterId">
          <el-select v-model="form.teacherCourseChapterId" clearable placeholder="请选择">
            <el-option v-for="item in chapters" :key="item.id" :label="item.name" :disabled="item.status === 0"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item> -->

        <el-form-item class="input" label="排序：" label-position="left">
          <el-input v-model="form.sort" style="width: 200px"></el-input>
        </el-form-item>

        <!--        <el-form-item-->
        <!--          label="时长："-->
        <!--          label-position="left"-->
        <!--          class="input"-->
        <!--          prop="workload"-->
        <!--        >-->
        <!--        -->
        <!--          <el-input style="width: 200px" v-model="form.workload">-->
        <!--            <template slot="append">分钟</template></el-input>-->
        <!--        </el-form-item>-->

        <el-form-item>
          <el-button
            :loading="loading"
            size="small"
            type="primary"
            @click="onSubmit('form')"
            >保存
          </el-button>
          <router-link
            :to="
              '/teacherCourse/detail/' + this.form.teacherCourseId + '/manage'
            "
            class="router-link"
          >
            <el-button size="small" style="margin-left: 10px">取消</el-button>
          </router-link>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import editor from "../../../components/editor";
import { mapActions } from "vuex";
import mediaUpload from "../../../components/mediaUpload";
import { config } from "@/js/config";

export default {
  name: "addClass",
  components: { editor, mediaUpload },
  data() {
    return {
      imageUrl: config.imageUrl,
      statusTxt: "上架",
      payStatusTxt: "关闭",
      loading: false,
      detailLoading: false,
      inputVisible: false,
      inputValue: "",
      uploadPath: "teacherCourse/" + this.$cookies.get("userId") + "/class",
      chapters: [],
      courseId: 0,
      pic: "",
      form: {
        id: 0,
        name: "",
        type: 2,
        contents: "",
        teacherCourseChapterId: "",
        teacherCourseId: "",
        data: "",
        size: 0,
        mimeType: "",
        sort: 0,
        // workload: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入课程名称", trigger: "blur" },
          {
            min: 2,
            max: 24,
            message: "长度在 2 到 24 个字符",
            trigger: "blur",
          },
        ],
        videoId: [
          {
            type: "number",
            required: true,
            message: "请选择视频",
            trigger: "change",
          },
        ],
        chapterId: [
          {
            type: "number",
            required: true,
            message: "请选择所属章节",
            trigger: "change",
          },
        ],
        contents: [
          {
            type: "string",
            required: true,
            message: "请输入内容",
            trigger: "blur",
          },
        ],
        data: [
          {
            type: "string",
            required: true,
            message: "请选择文件",
            trigger: "change",
          },
        ],
        workload: [
          {
            type: "string",
            required: true,
            message: "请输入内容",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    ...mapActions("teacherCourse", [
      "getTeacherChapterList",
      "addTeacherCourseClass",
      "getTeacherCourseClassDetail",
      "teacherClassAdd",
    ]),
    getAudioDuration(src) {
      let _this = this;
      let audio = document.createElement("audio"); //生成一个audio元素
      audio.src = src; //音乐的路径
      audio.addEventListener("canplay", function () {
        console.log(audio.duration);
        _this.form.workload = String(parseInt(audio.duration));
      });
    },
    getVideoDuration(src) {
      let _this = this;
      console.log(src);
      let video = document.createElement("video"); //生成一个audio元素
      this.$nextTick(() => {
        video.src = src; //音乐的路径
        video.addEventListener("canplay", function () {
          console.log(video.duration);
          _this.form.workload = String(parseInt(video.duration));
        });
      });
    },
    uploadSuccess(data) {
      let name = data.path.split("/");
      let arr = name[name.length - 1];
      let index = arr.indexOf("_");
      if (index === -1 || index === 0)
        return this.$message.error(
          "文件命名不正确，请重命名后重新选择上传：(章节名称_小节名称)"
        );
      this.form.data = data.path;
      this.form.mimeType = data.mimeType;

      this.form.size = data.size;
      if (this.form.type === 3) {
        this.getAudioDuration(this.imageUrl + data.path);
      } else {
        this.getVideoDuration(this.imageUrl + data.path);
      }
    },
    changeType() {
      this.form.data = "";
    },
    async onSubmit(formName) {
      if (!this.validateForm(formName)) {
        this.$message.error("请填写必填选项！");
        return false;
      }

      let _this = this;
      this.loading = true;

      console.log(this.form);
      console.log("-----------------");

      this.teacherClassAdd(this.form)
        .then((res) => {
          console.log(res);
          if (res.ret === 0) {
            this.$message.success("修改成功！");
            this.loading = false;

            this.$router.push(
              "/teacherCourse/detail/" + this.form.teacherCourseId + "/manage"
            );
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    validateForm(formName) {
      let validate = false;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          validate = true;
          return true;
        } else {
          validate = false;
          return false;
        }
      });
      return validate;
    },
    setStatus(status) {
      if (this.form.timing === true) {
        this.$message.info("已开启定时上架，无法设置上架");
        this.form.status = false;
        return;
      }
      console.log(status);
      if (this.form.status) {
        this.statusTxt = "上架";
      } else {
        this.statusTxt = "下架";
      }
      this.form.status = status;
    },
    setPayStatus() {
      if (this.form.free === 1) {
        this.payStatusTxt = "开启";
      } else {
        this.payStatusTxt = "关闭";
        this.form.amount = 0;
        this.form.real_amount = 0;
        this.form.indate = 0;
      }
    },
    handleClose(tag) {
      this.form.tags.splice(this.form.tags.indexOf(tag), 1);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.form.tags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    onChange(contents) {
      console.log(contents);
      this.form.contents = contents;
    },
  },
  async mounted() {
    this.detailLoading = true;
    this.form.teacherCourseId = this.$route.params.teacherCourseId;
    this.form.id = this.$route.params.id;
    console.log(this.form.teacherCourseId);

    let detail = await this.getTeacherCourseClassDetail(this.form.id);
    this.form.name = detail.data.name;
    this.form.type = detail.data.type;
    this.form.size = detail.data.size;
    this.form.mimeType = detail.data.mimeType;
    this.form.data = detail.data.data;
    this.form.sort = detail.data.sort;
    this.form.contents = detail.data.content;
    // this.form.workload = detail.data.workload;
    this.form.teacherCourseChapterId = detail.data.teacher_course_chapter_id;
    console.log(detail);

    let res = await this.getTeacherChapterList({
      teacherCourseId: this.form.teacherCourseId,
    });
    this.chapters = res.data;
    this.detailLoading = false;
  },
};
</script>

<style>
.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.videoImage {
  width: 200px;
  height: 120px;
}
</style>